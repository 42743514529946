import {
  FeatureServiceBinder,
  FeatureServiceProviderDefinition,
  SharedFeatureService,
} from '@feature-hub/core';
import { ContentReference } from '@oneaudi/falcon-tools';
import {
  ConfigurationServiceV1,
  ConfigurationServiceV1Implementation,
} from './v1/configuration-service';

export { ConfigurationServiceV1 };

export interface SharedConfigurationService extends SharedFeatureService {
  readonly '1.0.0': FeatureServiceBinder<ConfigurationServiceV1>;
}

export enum HighlightTechdataKeys {
  PREUSE_DETAIL = 'PreuseDetail',
}

export interface HighLightItem {
  IconLabel?: string;
  TechdataKey?: WarrantyNameTypes | HighlightTechdataKeys | string;
  InfoLink?: string;
}

export interface DetailHighlightsData {
  HighlightIcons: HighLightItem[];
}

export const warrantyCodeLookup = {
  WarrantyTypeNap: 'used-car-warranty-type.NAP',
  Warranty: 'used-car-warranty-type.warranty',
  WarrantyTypeWarranty: 'used-car-warranty-type.warranty',
  WarrantyTypePlus: 'used-car-warranty-type.plus',
  WarrantyType5Years: 'used-car-warranty-type.5-years',
  WarrantyTypeAsgExtended: 'used-car-warranty-type.asg-extended',
  WarrantyTypeAsg: 'used-car-warranty-type.asg',
  WarrantyTypeGwb: 'used-car-warranty-type.gwplus5-years-extended',
  WarrantyType12Months: 'used-car-warranty-type.twelve-months',
  WarrantyTypeClp: 'used-car-warranty-type.clp',
  WarrantyTypeCpo: 'used-car-warranty-type.cpo',
};

export type WarrantyNameTypes = keyof typeof warrantyCodeLookup;

export interface VTPConfiguration {
  scopes?: {
    iframeForms: boolean;
    financeEnabled?: boolean;
    hideEcom?: boolean;
    showAvailableSoonDate: boolean;
    hideConcreteAvailableSoonDate: boolean;
    showFreeHotline: boolean;
    useDynamicPriceLabels: boolean;
    showNwsDisclaimer: boolean;
    hideRegularPrice: boolean;
    hidePrices: boolean;
    hideAllInPricing: boolean;
    financeOption: FinanceOptionType;
    hideFinanceInTiles: boolean;
    interpretDisclaimersTextStyle: boolean;
    hideCalculationDisclaimer: boolean;
    hideRateChangeCTA: boolean;
    hideFinanceForEcom: boolean;
    hideDynamicFinancingLayerContact: boolean;
    showVIN: boolean;
    hideCommissionNumber: boolean;
    forcePhoneAsPrimary: boolean;
    phoneWithNumber: boolean;
  };
  enableOneCMSLayout?: boolean;
  cta?: CTAConfig[];
  urls?: {
    scStartPageLink?: string;
    eecImageUrl?: string;
    warrantyPlusLogoURL?: string;
  };
  assets?: {
    warrantyPlusLogo?: ContentReference;
  };
  featureApps?: {
    consumptionVersion: string;
    warrantiesVersion: string;
  };
  financeLayer?: {
    hideFinanceTable: boolean;
    disclaimerListItems?: DisclaimerListItem[];
  };
  scs?: {
    scsMarketPath?: string;
  };
  infoLayerLinks?: InfoLayerConfig[];
  mileageUnit?: string;
  currency?: string;
  currencyPattern: PatternType;
  datePattern?: DatePatternType;
  consumptionEmission?: ConsumptionAndEmission;
  scContextId?: string;
  notFound?: string | { path: string };
  useEfficiencyImage?: boolean;
  highLightItem?: HighLightItem[];
  warrantyTechDataItems?: WarrantyTechDataItem[];
  sortParams?: SortParameters;
  overWriteSorting?: OverwriteSortingBasedOnFilterSet;
}

export interface SortParameters {
  defaultOption?: string;
  options: SortOption[];
}

export type SortOption =
  | 'relevance'
  | 'prices.retail:asc'
  | 'prices.retail:desc'
  | 'prices.allIn:asc'
  | 'prices.allIn:desc'
  | 'powerKw:asc'
  | 'powerKw:desc'
  | 'modelName:asc'
  | 'modelName:desc'
  | 'byDistance:asc'
  | 'byDistance:desc'
  | 'mileage:asc'
  | 'mileage:desc'
  | 'initialRegistration:asc'
  | 'initialRegistration:desc'
  | 'rate:asc'
  | 'rate:desc'
  | 'salesModelYear:asc'
  | 'salesModelYear:desc'
  | 'dateOffer:asc'
  | 'dateOffer:desc'
  | 'productionYear:asc'
  | 'productionYear:desc'
  | 'modelYear:asc'
  | 'modelYear:desc'
  | 'modelNameProductionYear:desc'
  | 'modelNameProductionYear:asc'
  | 'electricRange:asc'
  | 'electricRange:desc'
  | 'actionVehicles';

export interface OverwriteSortingBasedOnFilterSet {
  filterToTriggerSorting?: string;
  overrideDefaultSortingKey?: string;
}

export enum FinanceOptionType {
  MINIMAL = 'MINIMAL',
  FULL = 'FULL',
  STATIC_WITH_DISCLAIMER = 'STATIC_WITH_DISCLAIMER',
}

export type CTAType =
  | 'aoz'
  | 'bevAgency'
  | 'central-customer-hotline'
  | 'contact'
  | 'custom'
  | 'ecom'
  | 'leasing'
  | 'liteReservation'
  | 'nws'
  | 'finance-checkout'
  | 'phone'
  | 'details';

export interface CTAConfig {
  type: CTAType;
  label: string;
  url: string;
  trackingCode?: string;
  showButtonInLayer?: boolean;
  method: 'GET' | 'POST';
  notFoundPageUrl?: string;
  dataProfile?: 'generic' | 'finance-checkout-only' | 'no-finance-data';
  target: 'same-window' | 'new-window' | 'open-in-layer';
  displayOption: 'tiles' | 'carinfo' | 'both' | 'none';
  reservation: 'reserved' | 'notReserved' | 'both';
  buyableOnline?: 'buyableOnline' | 'not-buyableOnline' | 'ignored';
  options?: {
    replacePhone?: boolean;
    filterByDealerId: {
      filterList?: string;
      filterType: 'none' | 'include' | 'exclude';
    };
  };
}

export interface InfoLayerConfig {
  type:
    | 'available-from.immediately'
    | 'ecom'
    | 'financing-type.leasing'
    | 'nws'
    | 'used-car-warranty-type.5-years'
    | 'used-car-warranty-type.plus'
    | 'used-car-warranty-type.NAP'
    | 'used-car-warranty-type.cpo'
    | 'used-car-warranty-type.gwplus5-years-extended'
    | 'used-car-warranty-type.asg-extended';
  url: string;
  label?: string;
}

export interface WarrantyTechDataItem {
  techDataKey:
    | 'WarrantyTypePlus'
    | 'WarrantyTypeWarranty'
    | 'WarrantyType5Years'
    | '12 Months Warranty'
    | 'WarrantyTypeAsg'
    | 'WarrantyTypeAsgExtended'
    | 'WarrantyTypeCpo';
  showInfoIcon: boolean;
  infoLayerLink: string;
  iconLabel: string;
}
export interface DisclaimerListItem {
  disclaimerPositionKey: 'Top' | 'Bottom';
  disclaimerMsgKey: 'productDisclaimer' | 'calculationDisclaimer' | 'globalDisclaimer';
}

/* eslint-disable no-shadow */
export enum PatternType {
  CURRENCY_PRICE = '{{currency}}{{price}}',
  PRICE_CURRENCY = '{{price}}{{currency}}',
  CURRENCY_TEXT_PRICE = '{{currency_text}}{{price}}',
  PRICE_CURRENCY_TEXT = '{{price}}{{currency_text}}',
}

export enum DatePatternType {
  DATE_MONTH_YEAR_DOT = 'dd.MM.yyyy',
  DATE_MONTH_YEAR_SLASH = 'dd/MM/yyyy',
  DATE_MONTH_YEAR_HYPHEN = 'dd-MM-yyyy',
  YEAR_MONTH_DATE_HYPHEN = 'yyyy-MM-dd',
  YEAR_MONTH_DATE_SLASH = 'yyyy/MM/dd',
  MONTH_DATE_YEAR_HYPHEN = 'MM-dd-yyyy',
  MONTH_DATE_YEAR_SLASH = 'MM/dd/yyyy',
  MONTH_YEAR_SLASH = 'MM/yyyy',
  MONTH_YEAR_HYPHEN = 'MM-yyyy',
  YEAR = 'yyyy',
}

export enum TestCycleTypesFromConfig {
  NEDC = 'nedc',
  WLTP = 'wltp',
}

export type EmissionRepresentationFromConfig =
  | TestCycleTypesFromConfig.NEDC
  | TestCycleTypesFromConfig.WLTP;

export interface ConsumptionAndEmission {
  scsConsumptionEmissionBlacklist: string;
  wltpLink: string;
  nedcLink: string;
  tilesEmissionRepresentation: EmissionRepresentationFromConfig[];
  consumptionEmissionRepresentation: EmissionRepresentationFromConfig[];
}

export type ConfigurationContainer = {
  config: VTPConfiguration | null;
};

export type ConfigurationServiceSubscribersCallback = (configuration: VTPConfiguration) => void;
export type ConfigurationServiceSubscribers = {
  [consumerId: string]: ConfigurationServiceSubscribersCallback[];
};

export function defineConfigurationService(
  initialConfiguration: VTPConfiguration | null = null
): FeatureServiceProviderDefinition<SharedConfigurationService> {
  return {
    id: 'vtp-configuration-service',
    optionalDependencies: {
      featureServices: {
        's2:async-ssr-manager': '^1.0.0',
        's2:server-request': '^1.0.0',
        's2:serialized-state-manager': '^1.0.0',
      },
    },
    create: (env) => {
      const subscribers: ConfigurationServiceSubscribers = {};
      const configurationContainer: ConfigurationContainer = { config: initialConfiguration };

      return {
        '1.0.0': (consumerId: string) => ({
          featureService: new ConfigurationServiceV1Implementation(
            consumerId,
            subscribers,
            configurationContainer,
            env.featureServices
          ),
        }),
      };
    },
  };
}

export * from './v1/configuration-service';
